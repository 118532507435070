const FooterComponent = () => {
    return <footer class="footer-area">
        <div class="footer-head">
            <div class="container">
                <div class="row">
                    <div class="col-sm-3 col-md-3">
                        <div class="f-h-content">
                            <h3>Call Us Now !</h3>
                            <h2><a href="tel:+919996341215">+91 9996341215</a></h2>
                            <h2><a href="tel:+917015608202">+91 7015608202</a></h2>
                            <p><a href="#">24/7 Available</a></p>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4">
                        <div class="f-h-content">
                            <h3>Send Us Message !</h3>
                            <h2><a href="mailto:asconsultancy1991@gmail.com">asconsultancy1991@gmail.com</a></h2>
                            <p><a href="#">24/7 Available</a></p>
                        </div>
                    </div>
                    <div class="col-sm-5 col-md-5">
                        <div class="f-h-content">
                            <h3>Visit Our Office !</h3>
                            <h2>Yamuna Nagar, HARYANA || Jaipur, Rajasthan || DELHI</h2>

                            <p><a href="#">View Map</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-content">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-4">
                        <div class="f-first-content f-all-h2">
                            <div class="f-content-img">
                                <a href="#"><img className="img img-fluid" height={60} width={92} src="/assets/images/logo/as_logo.png" alt="f-image" /></a>
                            </div>
                            <p>Empower businesses by providing innovative, reliable, and scalable solutions that enhance efficiency and effectiveness of our clients.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <div class="f-second-content f-all-h2">
                            <h2>Business Hours</h2>
                            <ul>
                                <li><a href="#">Opining Days :</a></li>
                                <li><a href="#">Monday – Friday : 9:30am to 7:30 pm</a></li>
                                <li><a href="#">Saturday : 9:30am to 5:30 pm</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Vacations :</a></li>
                                <li><a href="#">All Sunday Days</a></li>
                                <li><a href="#">All Official Holidays</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-2">
                        <div class="f-third-content f-all-h2">
                            <h2>Services</h2>
                            <ul>
                                {["GST Registration",
                                    "IEC Registration",
                                    "AD Code Registration",
                                    "Trademark Services",
                                    "FEMA Compliance",
                                    "FSSAI Compliance",
                                    // "Book keeping services for GST Appeals",
                                    // "RBI Compliance for E-Commerce Exporters",
                                    // "Consultancy on BRC related issues",
                                    // "Direct & Indirect Taxation",
                                    "Income Tax Return Filling"].map((item, index) => <li key={index}><a href="/services"><i class="fa fa-long-arrow-right"></i>{item}</a></li>)}
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <div class="f-fourth-content f-all-h2">
                            <h2>Newsletter</h2>
                            <p>Don’t miss to subscribe to our news feeds, kindly fill the form below.</p>
                            <form>
                                <div class="form-group footer-subscribe">
                                    <input type="email" class="form-control" id="Email1" placeholder="Subscribe In Our Newsletter" />
                                    <button type="submit" class="btn btn-default">Join</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="footer-bottom clearfix">
                    <div class="col-md-6 col-sm-6 no-padding-left">
                        <div class="f-bottom-left-text">
                            <p>AS Consultancy India © All Rights Reserved.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 no-padding-right">
                        <div class="f-bottom-right-text">
                            <ul>
                                <li><a href="#">News</a></li>
                                <li><a href="#">FAQs</a></li>
                                <li><a href="#">Careers</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms of Use</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>;
}

export default FooterComponent;