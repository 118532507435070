import {createStore, applyMiddleware, combineReducers} from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
// import { persistStore } from 'redux-persist';

import CashbackReducers from  './reducers/index';

const initialState={};
const middleware =[thunk];

const store = createStore(
    combineReducers({
        ...CashbackReducers,
    }),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
);

// export const persistor = persistStore( store );
export default store;