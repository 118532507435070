import { Link } from "react-router-dom";

const AboutUsScreen = () => {
    return (
        <>
            <section className="page-title-area page-title-type-5-bg">
                <div className="container">
                    <div className="row">
                        <div className="page-title-padding">
                            <div className="col-sm-12 col-md-6 page-title-content text-left">
                                <div className="p-title-breadcrumbs">
                                    <ul className="page-breadcrumbs">
                                        <li><a href={`/`}>home</a></li>
                                        <li>about us</li>
                                    </ul>
                                </div>
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-typo h-f-typo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="typo-column">
                                {/* <h2>Fullwidth</h2> */}
                                <p><strong style={{color:'black'}}>AS CONSULTANCY & SERVICES founded in 2017</strong>, The firm was established by Mr Ankit Arora as a
                                    sole proprietorship for providing Legal and Marketing consultancy to E-Commerce Sellers all over
                                    India. Thereafter, the firm was expanded from time to time and at present the firm is facilizing in
                                    different areas of sectors. The firm has also opened their office at Jaipur, Delhi. The firm is having
                                    head office in Yamuna Nagar Haryana, providing services in the field of Finance, Accounting,
                                    consultancy and financial management services to E-Commerce sellers in India. Our staff working on
                                    E-Commerce projects is well experienced &amp; qualified accountants with vast knowledge of E-
                                    Commerce platforms and their processes. So, they can efficiently handle the accounting and
                                    marketing related queries of E-Commerce clients.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="typo-column">
                                <h2>MISSION</h2>
                                <p>Our mission is to empower businesses by providing innovative, reliable, and scalable E-commerce
                                    solutions that enhance efficiency and effectiveness of our clients. We offer strategic consulting
                                    services to plan your E -Commerce success. We strive to be at the forefront of technological
                                    advancements and to contribute positively to the E-Commerce community.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsScreen;