import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
//store
import { Provider } from 'react-redux';
import store from './state/store';
import { theme } from './theme/index';

// routes
import Routes from "./Routes/Routes";
import HeaderComponent from "./app/pages/components/headerComponent";
import FooterComponent from "./app/pages/components/footerComponent";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <React.Suspense>
          <Router>
            <HeaderComponent />
            <Routes />
            <FooterComponent />
          </Router>
        </React.Suspense>
      </Provider>
    </ThemeProvider>
  );
};

export default App;