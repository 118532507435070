import { Link } from 'react-router-dom';
const HomeScreen = () => {
  return (
    <>
      <div className="rev_slider_wrapper">
        <div id="slider1" className="rev_slider" data-version="5.0">
          <ul>
            <li data-index="rs-9" data-transition="fade" data-slotamount="default" data-easein="default" data-easeout="default" data-masterspeed="default" data-delay="6510" data-rotate="0" data-saveperformance="off" data-title="Slide" data-description="">
              <img src="/assets/images/page-title-v5.jpg" alt="" width="2000" height="1333" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" className="rev-slidebg" data-no-retina />
              {/* <div className="tp-caption tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-13"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['140','140','140','140']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:-50px;opacity:0;s:500;s:500;"
                data-start="700"
                data-responsive_offset="on"
                style={{ zIndex: 5 }}><img src="/assets/images/slider3_ly2.png" alt=""
                  width="38"
                  height="58"
                  data-ww="['38px','38px','38px','38px']"
                  data-hh="['58px','58px','58px','58px']"
                  data-no-retina />
              </div> */}

              {/* <div className="tp-caption tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-12"
                data-x="['center','center','center','center']"
                data-hoffset="['-75','-75','-75','-75']"
                data-y="['top','top','top','top']"
                data-voffset="['158','158','158','158']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:-50px;opacity:0;s:500;s:500;"
                data-start="900"
                data-responsive_offset="on"
                style={{ zIndex: 6 }}>
                <img src="/assets/images/slider3_ly1.jpg" alt="" width="32" height="18" data-ww="['32px','32px','32px','32px']" data-hh="['18px','18px','18px','18px']" data-no-retina />
              </div> */}
              {/* <div className="tp-caption tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-14"
                data-x="['center','center','center','center']"
                data-hoffset="['75','75','75','75']"
                data-y="['top','top','top','top']"
                data-voffset="['158','158','158','158']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:50px;opacity:0;s:500;s:500;"
                data-start="900"
                data-responsive_offset="on"
                style={{ zIndex: 7 }}>
                <img src="/assets/images/slider3_ly3.jpg" alt="" width="32" height="18" data-ww="['32px','32px','32px','32px']" data-hh="['18px','18px','18px','18px']" data-no-retina />
              </div> */}

              {/* <div className="tp-caption tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-15"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['217','217','217','217']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-responsive_offset="on" style={{ zIndex: 8 }}>
                <img src="/assets/images/line-white.jpg" alt="" width="384" height="2" data-ww="['384px','384px','384px','384px']" data-hh="['2px','2px','2px','2px']" data-no-retina />
              </div> */}

              <div className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-2"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['235','235','235','235']"
                data-width="none" data-height="none"
                data-whitespace="nowrap"
                data-fontsize="['55','80','60','60']"
                data-lineheight="['58','58','58','58']"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1500"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 9, whiteSpace: 'nowrap', fontSize: '55px', lineHeight: '100px',
                  fontWeight: 700, color: 'rgba(255, 255, 255, 1.00)', fontFamily: 'Lora'
                }}>AS Consultancy & Services
              </div>

              {/* <div className="tp-caption tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-16"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['338','338','338','338']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1800"
                data-responsive_offset="on" style={{ zIndex: 10 }}>
                <img src="/assets/images/line-white-text.png" alt="" width="384" height="16" data-ww="['384px','384px','384px','384px']" data-hh="['16px','16px','16px','16px']" data-no-retina />
              </div> */}

              <div className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-18"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['404','404','404','404']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:50px;opacity:0;s:500;s:500;"
                data-start="2100"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 11, whiteSpace: 'nowrap',
                  fontSize: '17px', lineHeight: '26px',
                  fontWeight: 400, color: 'rgba(229, 228, 228, 1.00)', fontFamily: 'Lora'
                }}>
                <div class="text-center hidden-xs" style={{ fontStyle: 'italic' }}>Provide legal and marketing consultancy to e-commerce sellers all over India.</div>
              </div>


              <div className="tp-caption small_thin_grey tp-resizeme rs-parallaxlevel-0" id="slide-9-layer-19"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['483','483','483','483']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:-50px;opacity:0;s:500;s:500;"
                data-start="2300"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 12,
                  whiteSpace: 'nowrap',
                  color: 'rgba(117, 117, 117, 1.00)',
                  padding: '1px 4px 1px 4px',
                  borderColor: `rgba(255, 214, 88, 1.00)`
                }}>
                <a href="https://wa.me/919996341215" target="_blank" class="btn btn-primary rev-slider-btn-1 rev-slider-btn-2">Request A free Consultation</a>
              </div>
            </li>
            <li data-index="rs-10" data-transition="fade" data-slotamount="default" data-easein="default" data-easeout="default" data-masterspeed="default" data-delay="6500" data-rotate="0" data-saveperformance="off" data-title="Slide" data-description="">
              <img src="/assets/images/bg-slider4.jpg" alt="" width="1920" height="1278" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg" data-no-retina />
              <div className="tp-caption tp-resizeme rs-parallaxlevel-0" id="slide-10-layer-20"
                data-x="['right','right','right','right']"
                data-hoffset="['0','0','0','0']"
                data-y="['bottom','bottom','bottom','bottom']"
                data-voffset="['0','0','0','0']"
                data-width="none" data-height="none"
                data-whitespace="normal"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:500;e:easeOutCirc;"
                data-transform_out="x:50px;opacity:0;s:500;s:500;"
                data-start="700" data-responsive_offset="on"
                style={{ zIndex: 5 }}>
                <img src="/assets/images/slider4-ly1.png" alt="" width="450" height="553" data-ww="['450px','450px','450px','450px']" data-hh="['553px','553px','553px','553px']" data-no-retina />
              </div>
              <div className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0" id="slide-10-layer-21"
                data-x="['right','right','right','right']"
                data-hoffset="['590','590','20','20']"
                data-y="['top','top','top','top']"
                data-voffset="['165','165','165','165']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:500;s:500;"
                data-start="700"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 6,
                  whiteSpace: 'nowrap',
                  fontSize: '17px',
                  lineHeight: '26px',
                  fontWeight: 400,
                  color: 'rgba(213, 170, 109, 1.00)',
                  fontFamily: 'Lora'
                }}><span class="sl-italic">WE ARE HERE TO HELP YOU</span>
              </div>
              <div className="tp-caption Fashion-BigDisplay text-right tp-resizeme rs-parallaxlevel-0" id="slide-10-layer-22" data-x="['right','right','right','right']"
                data-hoffset="['590','590','20','20']"
                data-y="['top','top','top','top']"
                data-voffset="['208','208','208','208']"
                data-width="none"
                data-height="none"
                data-fontsize="['50','50','40','30']"
                data-lineheight="['58','58','58','58']"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:-50px;opacity:0;s:500;s:500;"
                data-start="900"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 7,
                  whiteSpace: 'nowrap',
                  fontSize: '50px',
                  lineHeight: '50px',
                  fontWeight: 800,
                  color: 'rgba(255, 255, 255, 1.00)'
                }}
              ><span class="text-right heading-rp-small">IN E-COMMERCE<br /> COMPLIANCE</span>
              </div>
              <div className="tp-caption Fashion-BigDisplay text-right  tp-resizeme rs-parallaxlevel-0" id="slide-10-layer-23" data-x="['right','right','right','right']"
                data-hoffset="['590','590','20','0']"
                data-y="['top','top','top','top']"
                data-voffset="['336','336','336','336']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:-50px;opacity:0;s:500;s:500;"
                data-start="1100"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 8,
                  whiteSpace: 'nowrap',
                  fontSize: '17px',
                  lineHeight: '26px',
                  fontWeight: 400,
                  color: 'rgba(229, 228, 228, 1.00)',
                  fontFamily: 'Lora'
                }}>
                <span class="text-right sl-italic hidden-xs">Choose your e-commerce compliance partner wisely.</span>
              </div>

              <div className="tp-caption small_thin_grey tp-resizeme rs-parallaxlevel-0" id="slide-10-layer-19"
                data-x="['right','right','right','right']"
                data-hoffset="['590','590','20','20']"
                data-y="['top','top','top','top']"
                data-voffset="['450','450','450','450']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:-50px;opacity:0;s:500;s:500;"
                data-start="1300"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 9,
                  whiteSpace: 'nowrap',
                  color: 'rgba(117, 117, 117, 1.00)',
                  padding: '1px 4px 1px 4px',
                  borderColor: 'rgba(255, 214, 88, 1.00)'
                }}>
                <Link to={"/our-team"} class="btn btn-primary rev-slider-btn-1 rev-slider-btn-2">Meet Our Team</Link>
              </div>
            </li>
            <li data-index="rs-17" data-transition="fade" data-slotamount="default" data-easein="default" data-easeout="default" data-masterspeed="default" data-delay="6510" data-rotate="0" data-saveperformance="off" data-title="Slider1-Layer1" data-description="">
              <img src="/assets/images/bg-team.jpg" alt="" width="1920" height="1280" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg" data-no-retina />
              <div className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0" id="slide-17-layer-1"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['180','180','180','180']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:50px;opacity:0;s:500;s:500;"
                data-start="700"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 5,
                  whiteSpace: 'nowrap',
                  fontSize: '17px',
                  lineHeight: '28px',
                  fontWeight: 400,
                  color: 'rgba(229, 228, 228, 1.00)',
                  fontFamily: 'Lora'
                }}><span class="sl-italic">Your Legal Solution Starts Here !</span>
              </div>
              <div className="tp-caption Fashion-BigDisplay tp-resizeme rs-parallaxlevel-0" id="slide-17-layer-2"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['225','225','225','225']"
                data-width="none"
                data-height="none"
                data-fontsize="['50','50','40','25']"
                data-lineheight="['58','58','58','40']"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="x:-50px;opacity:0;s:500;s:500;"
                data-start="1200"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{
                  zIndex: 6,
                  whiteSpace: 'nowrap',
                  fontSize: '50px',
                  lineHeight: '58px',
                  fontWeight: 800,
                  color: 'rgba(255, 255, 255, 1.00)'
                }}>
                <div class="text-center heading-rp-small" style={{ textTransform: 'uppercase' }}>The Greatest & Strongest Firm <br /> You Can Trust</div>
              </div>

              <div className="tp-caption tp-resizeme rs-parallaxlevel-0" id="slide-17-layer-3"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['364','364','364','364']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="1700"
                data-responsive_offset="on"
                style={{ zIndex: 7 }}>
                <img src="/assets/images/line-slider.jpg" alt="" width="2" height="34" data-ww="['2px','2px','2px','2px']" data-hh="['34px','34px','34px','34px']" data-no-retina />
              </div>

              <div className="tp-caption small_text tp-resizeme rs-parallaxlevel-0" id="slide-17-layer-4"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['428','428','428','428']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:500;e:Power2.easeInOut;"
                data-transform_out="y:50px;opacity:0;s:500;s:500;"
                data-start="2200"
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
                style={{ zIndex: 8, whiteSpace: 'nowrap' }}>
                <a href="https://wa.me/919996341215" target="_blank" className="btn btn-default-alt rev-slider-btn-1">Request A free Consultation</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="home-3-contact">
        <div class="container">
          <div class="row no-margin">
            <div class="home-3-contact-details clearfix">
              <div class="col-md-8">
                <div class="h-3-contact-text">
                  <p>ONE FOREMOST INDIA LEADING COMPANY FOR ALL THE E-COMMERCE COMPLIANCES. WE HAVE THE EXPERIENCE TO DELIVER INBOUND E -COMMERCE RESULTS</p>
                </div>
              </div>
              <div class="col-md-4 no-padding">
                <div class="h-3-contact-button">
                  <Link to={"/contact-us"} class="btn btn-default">Contact Us</Link>
                  {/* <button type="button" class="btn btn-default">All Practice Areas</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="practice-area home-2-practice-area home-3-practice-area">
        <div class="container">
          <div class="row">
            <div class="h-2-practice-content clearfix">
              {["GST Registration",
                "IEC Registration",
                "AD Code Registration",
                "Trademark Services",
                "FEMA Compliance",
                "FSSAI Compliance",
                "GST Appeals",
                "Book keeping services for E-Commerce Sellers",
                "RBI Compliance for E-Commerce Exporters",
                "Consultancy on BRC related issues",
                "Direct & Indirect Taxation",
                "Income Tax Return Filling"].map((item, index) =>
                  <div class="col-sm-6 col-md-3" key={index}>
                    <div class="h-2-p-c-details">
                      <div class="h-2-p-c-default h-3-p-c-default">
                        <img src="/assets/images/home-3-blog-post-1.png" class="img-responsive" alt="" />
                        <h2><a href="#">{item}</a></h2>
                        <a href="/services">More <i class="fa fa-long-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                )}

            </div>
          </div>
        </div>
      </section>
      {/* <section class="our-history-area type-1-bg">
        <div class="welcome-title-area">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div class="Title-area">
                  <h3>Our History</h3>
                  <h2>All About Trust</h2>
                  <p>Trust is a business theme perfectly suited legal advisers and offices, lawyers, attorneys, counsels, advocates and other legal and law related services.</p>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div class="asset-button btn-text-left text-right top-margin">
                  <button type="button" class="btn btn-default">Find Out More About Trust</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="history-content-area">
          <div class="container">
            <div class="row">
              <div class="col-md-8 no-padding">
                <div class="history-right-content">
                  <div class="col-sm-6 col-md-6">
                    <div class="progress-left-img">
                      <img src="/assets/images/progress-left-img.png" class="img-responsive" alt="" />
                      <span><i class="fa fa-shield"></i></span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <div class="history-right-content-text">
                      <p>Trust is a business theme perfectly suited legal advisers and offices, lawyers, attorneys, and other legal and law related services. We have started as a sole practitioner providing services to the area community. Aiming to provide high quality legal consultancy, support and results for your legal issues. Using their expertise and experience, Trust law firm documents and builds their clients' cases to obtain the best results they could achieve.</p>
                      <img src="/assets/images/history-right-sign.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="progressbar">
                  <div class="progress_cont">
                    <div class="skill">Criminal Law<span class="pull-right"></span> </div>
                    <div class="progress">
                      <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '0%' }}> <span class="sr-only">94% Complete (success)</span> </div>
                    </div>
                  </div>
                  <div class="progress_cont">
                    <div class="skill">Indurance <span class="pull-right"></span> </div>
                    <div class="progress">
                      <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: '0%' }}> <span class="sr-only">94% Complete (success)</span> </div>
                    </div>
                  </div>
                  <div class="progress_cont">
                    <div class="skill">Financial Law <span class="pull-right"></span> </div>
                    <div class="progress">
                      <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ width: '0%' }}> <span class="sr-only">94% Complete (success)</span> </div>
                    </div>
                  </div>
                  <div class="progress_cont">
                    <div class="skill">Civil Litigation <span class="pull-right"></span> </div>
                    <div class="progress">
                      <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: '0%' }}> <span class="sr-only">94% Complete (success)</span> </div>
                    </div>
                  </div>
                  <div class="progress_cont">
                    <div class="skill">Other Areas <span class="pull-right"></span> </div>
                    <div class="progress">
                      <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" style={{ width: '0%' }}> <span class="sr-only">94% Complete (success)</span> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section class="testimonial-area home-3-testimonial">
        <div class="testimonial-content-area">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="Title-area">
                  <h3>History</h3>
                  <h2>Why Trust !</h2>
                </div>
                <div class="history-accordion">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default history-accordion-default">
                      <div class="panel-heading history-panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title history-panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><i class="fa fa-bookmark"></i>  Story & History</a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body history-panel-body">
                          <img src="/assets/images/accordio-body-img.png" alt="" />
                          <div class="accordion-body-text">
                            <p>Trust started as a sole practitioner providing services to the area community, our Office has now grown to five lawyers and provides expert legal representation. Trust Law Offices founded on the principles of truth, justice, accountability, and equal access. Our success has been the result of each attorney and staff members.</p>
                            <a href="#">More <i class="fa fa-long-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default history-accordion-default">
                      <div class="panel-heading history-panel-heading" role="tab" id="headingTwo">
                        <h4 class="panel-title history-panel-title">
                          <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><i class="fa fa-book"></i> Values & Philosophy
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div class="panel-body history-panel-body">
                          <img src="/assets/images/accordio-body-img.png" alt="" />
                          <div class="accordion-body-text">
                            <p>Trust started as a sole practitioner providing services to the area community, our Office has now grown to five lawyers and provides expert legal representation. Trust Law Offices founded on the principles of truth, justice, accountability, and equal access. Our success has been the result of each attorney and staff members.</p>
                            <a href="#">More <i class="fa fa-long-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default history-accordion-default">
                      <div class="panel-heading history-panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title history-panel-title">
                          <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><i class="fa fa-briefcase"></i> Mission & Services
                          </a>
                        </h4>
                      </div>
                      <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body history-panel-body">
                          <img src="/assets/images/accordio-body-img.png" alt="" />
                          <div class="accordion-body-text">
                            <p>Trust started as a sole practitioner providing services to the area community, our Office has now grown to five lawyers and provides expert legal representation. Trust Law Offices founded on the principles of truth, justice, accountability, and equal access. Our success has been the result of each attorney and staff members.</p>
                            <a href="#">More <i class="fa fa-long-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default history-accordion-default">
                      <div class="panel-heading history-panel-heading" role="tab" id="headingFour">
                        <h4 class="panel-title history-panel-title">
                          <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><i class="vc_tta-icon fa fa-graduation-cap"></i> Our Skills
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                        <div class="panel-body history-panel-body">
                          <img src="/assets/images/accordio-body-img.png" alt="" />
                          <div class="accordion-body-text">
                            <p>Trust started as a sole practitioner providing services to the area community, our Office has now grown to five lawyers and provides expert legal representation. Trust Law Offices founded on the principles of truth, justice, accountability, and equal access. Our success has been the result of each attorney and staff members.</p>
                            <a href="#">More <i class="fa fa-long-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="Title-area">
                  <h3>All About Us</h3>
                  <h2>Our Latest News</h2>
                </div>
                <div class="t-b-content-area">
                  <div class="col-md-6 col-sm-6 no-padding-left">
                    <div class="t-b-details">
                      <div class="t-b-img">
                        <img src="/assets/images/testimonial-blog-post-1.png" class="img-responsive" alt="blog-post" />
                        <div><span>JUNE</span><span>30</span></div>
                      </div>
                      <div class="t-b-comment">
                        <p>Posted In: <span>Legal Advice</span></p>
                        <p><span><a href="#"><i class="fa fa-comments"></i></a> 15</span>
                          <span><a href="#"><i class="fa fa-eye"></i></a> 11</span></p>
                      </div>
                      <h2><a href="#">It’s My Pleasure to graduate with excellent!</a></h2>
                      <p>We cannot expect people to have respect for laws and orders until we teach respect to those we have entrusted to enforce those laws all the time.</p>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 no-padding-right">
                    <div class="t-b-details">
                      <div class="t-b-img">
                        <img src="/assets/images/testimonial-blog-post-2.png" class="img-responsive" alt="blog-post" />
                        <div><span>JUNE</span><span>29</span></div>
                      </div>
                      <div class="t-b-comment">
                        <p>Posted In: <span>Law, Articles</span></p>
                        <p><span><a href="#"><i class="fa fa-comments"></i></a> 15</span>
                          <span><a href="#"><i class="fa fa-eye"></i></a> 11</span></p>
                      </div>
                      <h2><a href="#">All you want to know about industrial laws</a></h2>
                      <p>It’s no secret that the digital industry is booming. From exciting startups to global brands, companies are reaching out to digital agencies, responding to...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section class="contact-us home-3-contact-us">
        <div class="welcome-title-area">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div class="Title-area contact-title">
                  <h3>People Say</h3>
                  <h2>Clients Testimonials </h2>
                  <p>See what our clients say about us, we have been known for doing what we says, aiming to bring favourable results for its customers as soon as possible.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-form">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div id="home-3-testimonial-demo" class="owl-carousel owl-theme">
                  <div class="item">
                    <div class="owl-testimonial-content home-3-testimonial-slider">
                      <i class="fa fa-quote-left"></i>
                      <p>rust law firm documents and builds their clients' cases to obtain the best results they could achieve in their particular situation.</p>
                    </div>
                    <div class="testimonial-quote home-3-testimonial-quote">
                      <span>Begha</span>
                      <span>Art Director, 7oroof Agency.</span>
                    </div>
                  </div>
                  <div class="item">
                    <div class="owl-testimonial-content home-3-testimonial-slider">
                      <i class="fa fa-quote-left"></i>
                      <p>Aiming to provide high quality legal consultancy, support and results for your legal issues. Using their expertise and experience.</p>
                    </div>
                    <div class="testimonial-quote home-3-testimonial-quote">
                      <span>Habaza</span>
                      <span>UX Researcher.</span>
                    </div>
                  </div>
                  <div class="item">
                    <div class="owl-testimonial-content home-3-testimonial-slider">
                      <i class="fa fa-quote-left"></i>
                      <p>Trust has been known for doing what he says, aiming to bring favourable results for its customers as soon as possible.</p>
                    </div>
                    <div class="testimonial-quote home-3-testimonial-quote">
                      <span>Ahmed Hassan</span>
                      <span>UI Developer.</span>
                    </div>
                  </div>
                  <div class="item">
                    <div class="owl-testimonial-content home-3-testimonial-slider">
                      <i class="fa fa-quote-left"></i>
                      <p>Trust has been known for doing what he says, aiming to bring favourable results for its customers as soon as possible.</p>
                    </div>
                    <div class="testimonial-quote home-3-testimonial-quote">
                      <span>Ahmed Hassan</span>
                      <span>UI Developer.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="our-client-area">
        <div class="welcome-title-area">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div class="Title-area">
                  <h3>They Trust Us</h3>
                  <h2>Our Clients</h2>
                  <p>Results recommend Trust  Law Firm as a good lawyers office, a trusted partners of his customers’ business and a honest adviser and consultant for legal situation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="client-slider">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="client-content">
                  <div id="client-demo" class="owl-carousel owl-theme">
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-1.png" alt="" /></a>
                    </div>
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-2.png" alt="" /></a>
                    </div>
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-3.png" alt="" /></a>
                    </div>
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-4.png" alt="" /></a>
                    </div>
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-5.png" alt="" /></a>
                    </div>
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-6.png" alt="" /></a>
                    </div>
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-1.png" alt="" /></a>
                    </div>
                    <div class="item client-item">
                      <a href="#"><img src="/assets/images/client-2.png" alt="" /></a>
                    </div>
                  </div>
                  <div class="testimonial-customNavigation client-customNavigation">
                    <a class="btn client_prev"><i class="fa fa-long-arrow-left"></i></a>
                    <a class="btn client_next"><i class="fa fa-long-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default HomeScreen;
