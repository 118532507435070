import { Link } from "react-router-dom";

const OurTeamScreen = () => {
    return (
        <>
            <section class="page-title-area page-title-type-5-bg">
                <div class="container">
                    <div class="row">
                        <div class="page-title-padding">
                            <div class="col-sm-12 col-md-6 page-title-content text-left">
                                <div class="p-title-breadcrumbs">
                                    <ul class="page-breadcrumbs">
                                        <li><a href={`/`}>home</a></li>
                                        <li>our team</li>
                                    </ul>
                                </div>
                                <h2>Our Team</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="our-team-area attorney-1">
                <div class="team-member-area">
                    <div class="container">
                        <div class="row">
                            {[{
                                name: "TANYA",
                                designation: "E-COMMERCE SPECIALIZATION",
                                qualification: "B.COM SPECIALIZATION",
                                experience: '2 yrs',
                                text: 'Tanya is having more than 2 years of professional experience in e-commerce. She is engaged in GST related work of the entities, GST Refunds of E-commerce exporters, Issues relating BR/FIRC, AD Code Registration.',
                            },
                            {
                                name: 'MADHURI',
                                designation: "E-COMMERCE SPECIALIZATION",
                                qualification: "B.COM SPECIALIZATION",
                                experience: '2.5 yrs',
                                text: 'She is detail-oriented and highly motivated with a strong background in e-commerce accounting and there compliance, seeking an entry-level position to utilize skills in e-commerce era.',
                            },
                            {
                                name: 'SIMRANJEET KAUR',
                                designation: "E-COMMERCE SPECIALIZATION",
                                qualification: "B.COM SPECIALIZATION",
                                experience: '2 yrs',
                                text: 'She is goal–driven with expertise in e-commerce accounting consultant and there compliance',
                            },
                            {
                                name: 'NEHA',
                                designation: "E-COMMERCE SPECIALIZATION",
                                qualification: "B.COM SPECIALIZATION",
                                experience: '1.5 yrs',
                                text: 'She is engaged in GST related work of the entities, GST Refunds of E-commerce exporters, Issues relating BR/FIRC, AD Code Registration',
                            },
                            {
                                name: 'DEEPALI',
                                designation: "E-COMMERCE SPECIALIZATION",
                                qualification: "B.COM SPECIALIZATION",
                                experience: '1 yr',
                                text: 'Deepali is having more than 1 years of professional experience in e-commerce she is confident, multi-skilled & expertise in e-commerce consultant and their compliances',
                            }

                            ].map((team) =>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                    <div class="team-describe">
                                        <div class="team-content">
                                            <div class="team-img">
                                                <img src="/assets/images/team-member-1.png" alt="team-member" />
                                            </div>
                                            <div class="about-team-member text-center">
                                                <div class="team-describe-content">
                                                    <p>{team.text}</p>
                                                </div>
                                                {/* <div class="social-content-box">
                                                    <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                    <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                    <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                    <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div class="member-name text-center">
                                            <h2><a href="#">{team.name}</a></h2>
                                            <p style={{ color: '#D5AA6D' }}>{team.designation}
                                                ({team.experience} Exp)</p>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="team-describe">
                                    <div class="team-content">
                                        <div class="team-img">
                                            <img src="/assets/images/team-member-2.png" alt="team-member" />
                                        </div>
                                        <div class="about-team-member text-center">
                                            <div class="team-describe-content">
                                                <p>Using his expertise and experience, Mahmoud documents and builds his customers cases to obtain the best results they could achieve in their particular situation.</p>
                                            </div>
                                            <div class="social-content-box">
                                                <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="member-name text-center">
                                        <h2><a href="#">Ahmed Hassan</a></h2>
                                        <p>Civil Lawyer</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="team-describe">
                                    <div class="team-content">
                                        <div class="team-img">
                                            <img src="/assets/images/team-member-3.png" alt="team-member" />
                                        </div>
                                        <div class="about-team-member text-center">
                                            <div class="team-describe-content">
                                                <p>Using his expertise and experience, Mahmoud documents and builds his customers cases to obtain the best results they could achieve in their particular situation.</p>
                                            </div>
                                            <div class="social-content-box">
                                                <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="member-name text-center">
                                        <h2><a href="#">Mohamed Habaza</a></h2>
                                        <p>Business Lawyer</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="team-describe">
                                    <div class="team-content">
                                        <div class="team-img">
                                            <img src="/assets/images/team-member-4.png" alt="team-member" />
                                        </div>
                                        <div class="about-team-member text-center">
                                            <div class="team-describe-content">
                                                <p>Using his expertise and experience, Mahmoud documents and builds his customers cases to obtain the best results they could achieve in their particular situation.</p>
                                            </div>
                                            <div class="social-content-box">
                                                <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="member-name text-center">
                                        <h2><a href="#">Ahmed Abd Alhaleem</a></h2>
                                        <p>Criminal Defence</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="team-describe">
                                    <div class="team-content">
                                        <div class="team-img">
                                            <img src="/assets/images/team-member-5.png" alt="team-member" />
                                        </div>
                                        <div class="about-team-member text-center">
                                            <div class="team-describe-content">
                                                <p>Using his expertise and experience, Mahmoud documents and builds his customers cases to obtain the best results they could achieve in their particular situation.</p>
                                            </div>
                                            <div class="social-content-box">
                                                <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="member-name text-center">
                                        <h2><a href="#">Amr Sadek</a></h2>
                                        <p>Secretary Head</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="team-describe">
                                    <div class="team-content">
                                        <div class="team-img">
                                            <img src="/assets/images/team-member-6.png" alt="team-member" />
                                        </div>
                                        <div class="about-team-member text-center">
                                            <div class="team-describe-content">
                                                <p>Using his expertise and experience, Mahmoud documents and builds his customers cases to obtain the best results they could achieve in their particular situation.</p>
                                            </div>
                                            <div class="social-content-box">
                                                <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="member-name text-center">
                                        <h2><a href="#">Fouad Badwy</a></h2>
                                        <p>Family Lawyer</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="team-describe">
                                    <div class="team-content">
                                        <div class="team-img">
                                            <img src="/assets/images/team-member-7.png" alt="team-member" />
                                        </div>
                                        <div class="about-team-member text-center">
                                            <div class="team-describe-content">
                                                <p>Using his expertise and experience, Mahmoud documents and builds his customers cases to obtain the best results they could achieve in their particular situation.</p>
                                            </div>
                                            <div class="social-content-box">
                                                <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="member-name text-center">
                                        <h2><a href="#">Ahmed Emad Eldeen</a></h2>
                                        <p>Insurance Lawyer</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div class="team-describe">
                                    <div class="team-content">
                                        <div class="team-img">
                                            <img src="/assets/images/team-member-8.png" alt="team-member" />
                                        </div>
                                        <div class="about-team-member text-center">
                                            <div class="team-describe-content">
                                                <p>Using his expertise and experience, Mahmoud documents and builds his customers cases to obtain the best results they could achieve in their particular situation.</p>
                                            </div>
                                            <div class="social-content-box">
                                                <a href="#"> <i class="fa fa-facebook"></i> </a>
                                                <a href="#"> <i class="fa fa-twitter"></i> </a>
                                                <a href="#"> <i class="fa fa-google-plus"></i> </a>
                                                <a href="#"> <i class="fa fa  fa-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="member-name text-center">
                                        <h2><a href="#">Mostafa Amin</a></h2>
                                        <p>Trainer</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurTeamScreen;