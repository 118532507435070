import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "../app/pages/home";
import AboutUsScreen from "../app/pages/about-us";
import OurTeamScreen from "../app/pages/our-team";
import ContactUsScreen from "../app/pages/contact-us";
import ServicesScreen from "../app/pages/services";

const AppRoutes = props => {
    return (
        <Routes>
            <Route path="/" exact element={<HomeScreen />} />
            <Route path="/about-us" exact element={<AboutUsScreen />} />
            <Route path="/blog" exact element={<HomeScreen />} />
            <Route path="/career" exact element={<HomeScreen />} />
            <Route path="/contact-us" exact element={<ContactUsScreen />} />
            <Route path="/faq" exact element={<HomeScreen />} />
            <Route path="/our-team" exact element={<OurTeamScreen />} />
            <Route path="/services" exact element={<ServicesScreen />} />
        </Routes>
    );
}

export default AppRoutes;